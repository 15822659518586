main {
  margin-top: 60px;
}

form {
  width: 350px;
  margin-bottom: 10px;
}

ul {
  margin-bottom: 0px;
}

#main-content {
  margin-top:60px;
}